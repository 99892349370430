const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://6avhj0gwm8.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://d71hjr9l26.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.3',
    HOSTNAME: 'https://company.saas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.saas.forwoodsafety.com',
    WEBSOCKET: 'wss://k9ttmpq1n8.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;